.dd-list ol li .dd3-content {
  background-color: #efefef;
  border: 1px solid #efefef;
}

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}

.dd-item.dd-bg-color .dd3-handle,
.dd-item.dd-bg-color .dd3-content {
  border-color: #d9e7eb;
  background: #d9e7eb;
}

.dd-handle {
  display: block;
  height: 60px;
  padding: 15px;
  margin: 5px 0;
  text-decoration: none;
  background: #ececec;
  // border-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-sizing: border-box;
}

.dd-item > button[data-action="collapse"] {
  display: none;
}

.bg-dark .dd-placeholder,
.bg-dark .dd-empty {
  background: #313b60;
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
    ),
    -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff
          75%, #fff);
  background-image: -moz-linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
    ),
    -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff
          75%, #fff);
  background-image: linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
    ),
    linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
    );
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

.dd3-content {
  display: block;
  height: 60px;
  margin: 12px 0;
  padding: 8px 20px 5px 80px;
  color: var(--c-black);
  text-decoration: none;
  border: 1px solid #ececec;
  background: #ececec;
  // border-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  border-style: dotted dashed solid double;
}

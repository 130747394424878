body {
  font-family: 'Noto Sans Thai', sans-serif;
}
// Here you can add other styles
.btn-primary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #2563EB;
  --cui-btn-border-color: #2563EB;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #1a49af;
  --cui-btn-hover-border-color: #4735df;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #2f57ad;
  --cui-btn-active-border-color: #4735df;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #2563EB;
  --cui-btn-disabled-border-color: #2563EB;
}

.btn-danger {
  --cui-btn-color: #fff;
  --cui-btn-bg: #EF4444;
  --cui-btn-border-color: #EF4444;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #e96d6d;
  --cui-btn-hover-border-color: #e86464;
  --cui-btn-focus-shadow-rgb: 195, 71, 74;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #ea7575;
  --cui-btn-active-border-color: #e86464;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #EF4444;
  --cui-btn-disabled-border-color: #EF4444;
}

.btn-outline-dark {
  --cui-btn-color: #ABADC6;
  --cui-btn-border-color: #a0a3b3;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #727d8f;
  --cui-btn-hover-border-color: #616d81;
  --cui-btn-focus-shadow-rgb: 79, 93, 115;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #727d8f;
  --cui-btn-active-border-color: #616d81;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #4f5d73;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-export {
  --cui-btn-color: #fff;
  --cui-btn-bg: #424867;
  --cui-btn-border-color: #727d8f;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #484f5b;
  --cui-btn-hover-border-color: #484f5b;
  --cui-btn-focus-shadow-rgb: 79, 93, 115;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #727d8f;
  --cui-btn-active-border-color: #616d81;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #4f5d73;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
}

.toast-alert {
  background-color: #FEF2F2;
  color: #EF4444;
  font-weight: 600;
  font-size: 12px;
  border: 0;
}

.toast-alert-success {
  background-color: #fff;
  color: green;
  font-weight: 600;
  font-size: 12px;
  border: 0;
}


.text-blue {
  color: var(--cui-blue);
}

.object-cover {
  object-fit: cover;
  width: 100%;
}

.form-label-w-600 .form-label{
  font-weight: 600;
  font-size: 14px;
}

a.text-decoration-none:hover, a.text-decoration-none:active, a.text-decoration-none:visited, a.text-decoration-none:link{
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.breadcrumb-item a,
li.breadcrumb-item {
  font-weight: 600;
}
.breadcrumb-item a {
  color: #ABADC6 !important;
  text-decoration: none;
}
.breadcrumb-item.active a {
  color: var(--cui-breadcrumb-item-active-color) !important;
  text-decoration: none;
}

.sidebar-nav .nav-item {
  padding: 0 10px;
  margin-bottom: 5px;
}
.sidebar-nav .nav-icon {
  margin-top: 2px;
}
.sidebar-nav .nav-item.nav-item-sub {
  padding-left: 0;
}
.sidebar-nav .nav-link {
  align-items: start;
}
.sidebar-nav .nav-link.active {
  border-radius: 10px;
}

.sidebar-nav .nav-group{
  padding: 0 10px;
  margin-bottom: 5px;
}
.sidebar-nav .nav-group.show {
  width: 92%;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 0;
}
.sidebar-nav .nav-group .nav-link.nav-group-toggle .nav-icon {
  margin-top: 2px;
}
.sidebar-nav .nav-group-toggle {
  border-radius: 10px;
}
.sidebar-nav .nav-group.show .nav-link.nav-group-toggle {
  color: #FFF;
}
.sidebar-nav .nav-group-toggle::after {
  flex: 0 1 18px;
  margin-top: 8px;
}
.sidebar-nav .nav-group-items .nav-link.nav-item-sub {
  color: #ABADC6;
  padding-top: 3px;
  padding-bottom: 3px;
}
.sidebar-nav .nav-group-items .nav-link.nav-item-sub:hover {
  background: var(--cui-sidebar-nav-link-bg);
  color: #EC4899;
}
.sidebar-nav .nav-group-items .nav-link.nav-item-sub.active {
  color: #EC4899;
}

//table
.table.table-custom {
  font-size: 14px;
}
.table.table-custom .text-muted{
  font-size: 12px;
}
.table.table-custom .cell-actions{
  width: 13%;
}
.table.table-custom .cell-actions-service {
  width: 10%;
}
.table.table-custom th {
  font-size: 12px;
  color: #ABADC6;
  padding-top: 15px;
}
.table.table-custom td {
  font-weight: 700;
  font-size: 14px;
}
.table.table-custom thead.thead-custom tr:first-child th:first-child { border-top-left-radius: 12px; }
.table.table-custom thead.thead-custom tr:first-child th:last-child { border-top-right-radius: 12px; }
.table.table-custom thead.thead-custom {
  background-color: #f9fafb;
}
.table.table-custom tfoot {
  background: var(--cui-table-striped-bg);
}
.table.table-custom tfoot tr:first-child td:first-child {
  border-bottom-left-radius: 12px;
  border-collapse: separate;
  box-shadow: 0 0.125rem 0 0 rgba(0, 0, 21, 0.075) !important;
}
.table.table-custom tfoot tr:first-child td:last-child {
  border-bottom-right-radius: 12px;
  border-collapse: separate;
  box-shadow: 0 0.125rem 0 0 rgba(0, 0, 21, 0.075) !important;
}
.table.table-custom tfoot td {
  font-weight: 400;
  font-size: 14px;
  color: #9FA2B4;
}

// form
.form-header-container {
  margin-top: 25px;
}
.form-header-title {
  font-family: 'Noto Sans Thai';
  font-size: 30px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 0;
}
.form-header-subtitle {
  font-family: 'Noto Sans Thai';
  font-size: 12px;
  color: #9CA3AF;
  margin-bottom: 0;
  font-weight: 400;
}
.form-label {
  font-weight: 600;
  font-size: 14px;
}
.form-select.form-select-custom {
  border-radius: 0.75rem;
}
.input-group .input-group-text.input-group-text-custom {
  background-color: #fff;
  border-radius: 0.75rem;
}
.input-group > .form-control.form-group-control-custom {
  border-left: 0;
  border-radius: 0.75rem;
  line-height: 0.9rem;
}
.picker-input-group-icon {
  width: 1.15rem;
  height: 1.1rem;
}
.date-picker.is-valid .date-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 512 512%27 role=%27img%27%3e%3cpath fill=%27%238a93a2%27 d=%27M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z%27%3e%3c/path%3e%3crect width=%2732%27 height=%2732%27 x=%27112%27 y=%27224%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27200%27 y=%27224%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27280%27 y=%27224%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27368%27 y=%27224%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27112%27 y=%27296%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27200%27 y=%27296%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27280%27 y=%27296%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27368%27 y=%27296%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27112%27 y=%27368%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27200%27 y=%27368%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27280%27 y=%27368%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3crect width=%2732%27 height=%2732%27 x=%27368%27 y=%27368%27 fill=%27%238a93a2%27%3e%3c/rect%3e%3c/svg%3e");
}
.time-picker.is-valid .time-picker-input-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 512 512%27 role=%27img%27%3e%3cpolygon fill=%27%238a93a2%27 points=%27271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5%27 class=%27ci-primary%27%3e%3c/polygon%3e%3cpath fill=%27%238a93a2%27 d=%27M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z%27 class=%27ci-primary%27%3e%3c/path%3e%3c/svg%3e");
}


//bage
.bg-pink {
  color: #DB2777;
  background-color: rgba(252, 231, 243, 1) !important;
}
.bg-yellow {
  color: #CA8A04;
  background-color: rgba(254, 240, 138, 1) !important;
}
.bg-blue {
  color: #1E40AF;
  background-color: rgba(219, 234, 254, 1) !important;
}
.bg-green {
  color: #14532D;
  background-color: rgba(220, 252, 231, 1) !important;
}
.bg-gray {
  color: #111827;
  background-color: rgba(229, 231, 235, 1) !important;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
};

.custom-text-editor .ql-container.ql-snow.ql-disabled {
  color: gray;
  border-color: #bdbfc8;
  opacity: 1;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3em;
  background-color: #3dcc4a !important;
  color: rgb(0, 0, 0);
}

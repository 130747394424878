.tb-row-voucher {
  height: 60px;
  text-align: center;
}

.tb-header-row-voucher {
  height: 40px;
}

// custom date swap invalid to valid using in date only
.custom-date .valid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353 !important;
}

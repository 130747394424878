// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800&display=swap');

// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// Plugins
@import "calendar";

// Prism.js
@import "examples";

// If you want to add custom CSS you can put it here.
@import "custom";

@import "nestable";

@import "voucher";

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #2563EB !important;
}
.was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #2563EB !important;
}